import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';
import volumePageData from '../content/volumes-page.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import coverImage from "../images/cover-image-original.jpg";
import { FaDownload } from "react-icons/fa";
import viewPDF from "../images/viewPDF.png";

const ViewArticlePageContent = () => {

    const { year, id } = useParams();
    const article = volumePageData[year].find((article) => article.documentName === id);

    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [viewArticle, setViewArticle] = useState(false);

    const handleAuthorClick = (author) => {
        setSelectedAuthor(author === selectedAuthor ? null : author);
    };

    const handleViewArticle = () => {
        setViewArticle(!viewArticle);
    };

    const addMetaData = ({ title, authors, description, doi, url, imageUrl, datePublished, journalName, articleType }) => {
        function createMetaTag(property, content) {
            const meta = document.createElement('meta');
            meta.setAttribute(property.includes('og:') ? 'property' : 'name', property);
            meta.setAttribute('content', content);
            document.head.appendChild(meta);
        }
    
        // adding Open Graph metadata
        createMetaTag('og:type', articleType || 'article');
        createMetaTag('og:title', title);
        createMetaTag('og:description', description);
        createMetaTag('og:url', url);
        createMetaTag('og:image', 'https://ejaam.org'+imageUrl);
        createMetaTag('og:site_name', 'e-Journal of Analysis and Applied Mathematics');
    
        // adding Schema.org JSON-LD
        let schemaData = {
            "@context": "http://schema.org",
            "@type": "ScholarlyArticle",
            "author": [],
            "headline": title,
            "datePublished": datePublished,
            "journalName": journalName,
            "url": url,
            "image": 'https://ejaam.org'+imageUrl,
            "doi": doi
        };
        authors.forEach(author => {
            schemaData.author.push({ "@type": "Person", "name": author.name});
        });
    
        const schemaScript = document.createElement('script');
        schemaScript.setAttribute('type', 'application/ld+json');
        schemaScript.textContent = JSON.stringify(schemaData);
        document.head.appendChild(schemaScript);
    
        // adding BIBO metadata
        createMetaTag('bibo:doi', doi);
        createMetaTag('bibo:authorList', authors.map(author => author.name).join(', '));
        createMetaTag('bibo:abstract', description);
        createMetaTag('bibo:Journal', 'e-Journal of Analysis and Applied Mathematics');
    
        // adding Google Scholar meta tags
        createMetaTag('citation_title', title);
        authors.forEach(author => {
            createMetaTag('citation_author', author.name);
        });
        createMetaTag('citation_publication_date', datePublished);
        createMetaTag('citation_journal_title', 'e-Journal of Analysis and Applied Mathematics');
        createMetaTag('citation_doi', doi);
        createMetaTag('citation_fulltext_html_url', url);
        createMetaTag('citation_pdf_url', url.replace('html', 'pdf'));
        createMetaTag('citation_language', 'en');
    };    

    useEffect(() => {
        if (article) {
            addMetaData({
                title: article.title,
                authors: article.authors,
                description: article.abstract,
                doi: article.doi,
                url: window.location.href,
                imageUrl: coverImage,
                datePublished: article.publishedOnline,
                journalName: "e-JAAM",
                articleType: "Article"
            });
        }
    }, [article]);

    if (!article) {
        return <div>Article not found</div>;
    };

    return (
        <div> 
            <section className="main-section">
                <div className="container">
                    <div className="header">
                        <div className="logo">
                            <img src={coverImage} alt="Logo" />
                            <p>Volume: {year}</p>
                        </div>  
                    </div>
                    <div className="article-detail">
                        <h1><Latex>{article.title}</Latex></h1>
                        <p><strong>Authors:</strong> {article.authors.map((author, index) => (
                            <span key={index}>
                            <span className="author" onClick={() => handleAuthorClick(author)}>
                                {author.name} {selectedAuthor === author ? '-' : '+'}
                            </span>
                            {index < article.authors.length - 1 && ', '}
                            </span>
                        ))}</p>
                        {selectedAuthor && (
                            <div className='author-detail'>
                                {selectedAuthor.address.map((address, index) => (
                                    <p key={index}>{address}</p>
                                ))}
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                    <a> </a>
                                    <a className="author-mail" href={`mailto:${selectedAuthor.email}`}>{selectedAuthor.email}</a>
                                </div>
                            </div>
                        )}
                        <p><strong>Published Online:</strong> {article.publishedOnline}</p>
                        <p><strong>Page Range:</strong> {article.pageRange}</p>
                        
                        <p><strong>Abstract: </strong><Latex>{article.abstract}</Latex></p>
                        <p/>
                        <p><strong>Keywords: </strong>{article.keywords.join(', ')}</p>
                        <p><strong>{article.classification.year}: </strong>{article.classification.types.join(', ')}</p>
                        <p><string>DOI: </string><a href={`https://${article.doi}`}>{article.doi}</a></p>
                        <p className="document">
                            <div className="pdf">
                                <a className="img" onClick={handleViewArticle}>
                                    <img src={viewPDF} alt="View" />
                                </a>
                                <a href={article.path} download={article.documentName} target='_blank'>
                                    <FaDownload className="icon"/>
                                </a>
                            </div>
                        </p>
                        { viewArticle ? <embed src={article.path} width="100%" height="1000px" /> : <div/> }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ViewArticlePageContent;